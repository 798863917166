/**
 * @generated SignedSource<<0984cbd278412b9cbb1fcdd633c9a134>>
 * @relayHash 528dc4f1a403e4677c8dff7f038a5b81
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae8466626b70d37bb5a9afbcf747f9ea6acae64e4933c999471c8bcf07caad31

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type realtimeUpdaterOld_issueDetails_Query$variables = {
  fieldSetIds: ReadonlyArray<string>;
  id: string;
  isDensityFull: boolean;
  isHierarchyEnabled: boolean;
  isInlineEditingEnabled: boolean;
  projectKey: string;
};
export type realtimeUpdaterOld_issueDetails_Query$data = {
  readonly jira: {
    readonly issueById: {
      readonly canHaveChildIssues?: boolean | null | undefined;
      readonly fieldSetsById: {
        readonly __id: string;
        readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
      } | null | undefined;
      readonly issueId: string;
      readonly issueTypeField?: {
        readonly id: string;
        readonly issueType: {
          readonly hierarchy: {
            readonly level: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly key: string;
    } | null | undefined;
  } | null | undefined;
};
export type realtimeUpdaterOld_issueDetails_Query = {
  response: realtimeUpdaterOld_issueDetails_Query$data;
  variables: realtimeUpdaterOld_issueDetails_Query$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDensityFull"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHierarchyEnabled"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInlineEditingEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v8 = {
  "kind": "ScalarField",
  "name": "key"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v11 = {
  "args": [
    {
      "kind": "Variable",
      "name": "projectKey",
      "variableName": "projectKey"
    }
  ],
  "kind": "ScalarField",
  "name": "canHaveChildIssues"
},
v12 = [
  {
    "kind": "Variable",
    "name": "fieldSetIds",
    "variableName": "fieldSetIds"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v14 = {
  "kind": "ScalarField",
  "name": "type"
},
v15 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v16 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v17 = {
  "kind": "ScalarField",
  "name": "name"
},
v18 = {
  "kind": "ScalarField",
  "name": "value"
},
v19 = [
  (v18/*: any*/),
  (v9/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v20 = {
  "condition": "isInlineEditingEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lazyIsEditableInIssueView"
        }
      ]
    }
  ]
},
v21 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v22 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v23 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v24 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v25 = {
  "kind": "ScalarField",
  "name": "text"
},
v26 = {
  "kind": "InlineFragment",
  "selections": [
    (v25/*: any*/),
    (v17/*: any*/),
    (v20/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v27 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v28 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v9/*: any*/)
  ]
},
v29 = [
  (v28/*: any*/)
],
v30 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v31 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v32 = {
  "kind": "ScalarField",
  "name": "picture"
},
v33 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v34 = [
  (v17/*: any*/),
  (v9/*: any*/)
],
v35 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v36 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v37 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v36/*: any*/),
    (v9/*: any*/)
  ]
},
v38 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v25/*: any*/),
    (v9/*: any*/)
  ]
},
v39 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v28/*: any*/),
    (v9/*: any*/)
  ]
},
v40 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v41 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v40/*: any*/),
        (v17/*: any*/),
        (v9/*: any*/)
      ]
    },
    (v9/*: any*/)
  ]
},
v42 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v43 = {
  "kind": "ScalarField",
  "name": "number"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "realtimeUpdaterOld_issueDetails_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueById",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "condition": "isHierarchyEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeField",
                    "kind": "LinkedField",
                    "name": "issueTypeField",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "issueType",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              {
                "args": (v12/*: any*/),
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsById",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "isDensityFull",
                        "variableName": "isDensityFull"
                      },
                      {
                        "kind": "Variable",
                        "name": "isInlineEditingEnabled",
                        "variableName": "isInlineEditingEnabled"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets"
                  },
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "realtimeUpdaterOld_issueDetails_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueById",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": (v12/*: any*/),
                "concreteType": "JiraIssueFieldSetConnection",
                "kind": "LinkedField",
                "name": "fieldSetsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "fieldSetId"
                          },
                          {
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fields",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v16/*: any*/),
                                      (v14/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isJiraIssueField"
                                      },
                                      (v9/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraAffectedServiceConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedAffectedServicesConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraAffectedServiceEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraAffectedService",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "serviceId"
                                                      },
                                                      (v17/*: any*/),
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraAffectedServicesField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraCascadingOption",
                                                "kind": "LinkedField",
                                                "name": "cascadingOption",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "parentOptionValue",
                                                    "plural": false,
                                                    "selections": (v19/*: any*/)
                                                  },
                                                  {
                                                    "concreteType": "JiraOption",
                                                    "kind": "LinkedField",
                                                    "name": "childOptionValue",
                                                    "plural": false,
                                                    "selections": (v19/*: any*/)
                                                  }
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraCascadingSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "args": (v21/*: any*/),
                                                "concreteType": "JiraComponentConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedComponentsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponent",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v22/*: any*/),
                                                          (v23/*: any*/),
                                                          (v9/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedComponentsConnection(first:1000)"
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraComponentsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v24/*: any*/),
                                              (v17/*: any*/),
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraDateTimePickerField"
                                          },
                                          (v26/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraCommentSummary",
                                                "kind": "LinkedField",
                                                "name": "commentSummary",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "canAddComment"
                                                  },
                                                  (v27/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraCommentSummaryField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "uri"
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraUrlField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "fieldOptions",
                                                "plural": false,
                                                "selections": [
                                                  (v27/*: any*/)
                                                ]
                                              },
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "fieldOption",
                                                "plural": false,
                                                "selections": [
                                                  (v18/*: any*/),
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": (v29/*: any*/)
                                                  },
                                                  {
                                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": (v29/*: any*/)
                                                  },
                                                  (v9/*: any*/),
                                                  (v30/*: any*/)
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedOptions",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOptionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v9/*: any*/),
                                                          (v18/*: any*/),
                                                          (v30/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraMultipleSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraOptionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedOptions",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraOptionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraOption",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v9/*: any*/),
                                                          (v18/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraCheckboxesField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraUserConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedUsersConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraUserEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v15/*: any*/),
                                                          (v31/*: any*/),
                                                          (v17/*: any*/),
                                                          (v32/*: any*/),
                                                          (v9/*: any*/),
                                                          (v33/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "email"
                                                              }
                                                            ],
                                                            "type": "AtlassianAccountUser"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraMultipleSelectUserPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraTeamView",
                                                "kind": "LinkedField",
                                                "name": "selectedTeam",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "jiraSuppliedId"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "jiraSuppliedName"
                                                  }
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraTeamViewField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "issue",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraTimeTrackingField",
                                                    "kind": "LinkedField",
                                                    "name": "timeTrackingField",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      (v20/*: any*/),
                                                      {
                                                        "concreteType": "JiraEstimate",
                                                        "kind": "LinkedField",
                                                        "name": "originalEstimate",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "timeInSeconds"
                                                          }
                                                        ]
                                                      },
                                                      {
                                                        "concreteType": "JiraTimeTrackingSettings",
                                                        "kind": "LinkedField",
                                                        "name": "timeTrackingSettings",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "workingHoursPerDay"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "workingDaysPerWeek"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "defaultFormat"
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "defaultUnit"
                                                          }
                                                        ]
                                                      },
                                                      (v16/*: any*/),
                                                      (v17/*: any*/),
                                                      (v14/*: any*/)
                                                    ]
                                                  },
                                                  (v9/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraOriginalTimeEstimateField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "selectedOption",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": "label",
                                                    "kind": "ScalarField",
                                                    "name": "value"
                                                  },
                                                  (v9/*: any*/),
                                                  (v22/*: any*/)
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraRadioSelectField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "args": (v21/*: any*/),
                                                "concreteType": "JiraVersionConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedVersionsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraVersionEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraVersion",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v9/*: any*/),
                                                          (v17/*: any*/),
                                                          (v22/*: any*/),
                                                          (v23/*: any*/),
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "versionId"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedVersionsConnection(first:1000)"
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraMultipleVersionPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "args": (v21/*: any*/),
                                                "concreteType": "JiraGoalConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedGoals",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraGoalEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraGoal",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v9/*: any*/),
                                                          (v8/*: any*/),
                                                          (v15/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "cursor"
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    "concreteType": "PageInfo",
                                                    "kind": "LinkedField",
                                                    "name": "pageInfo",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "endCursor"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "hasNextPage"
                                                      }
                                                    ]
                                                  }
                                                ],
                                                "storageKey": "selectedGoals(first:1000)"
                                              },
                                              {
                                                "args": (v21/*: any*/),
                                                "handle": "connection",
                                                "key": "Goals_selectedGoals",
                                                "kind": "LinkedHandle",
                                                "name": "selectedGoals"
                                              }
                                            ],
                                            "type": "JiraGoalsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "args": (v21/*: any*/),
                                                "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                                                "kind": "LinkedField",
                                                "name": "confluenceRemoteIssueLinks",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraConfluenceRemoteIssueLink",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v9/*: any*/),
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "href"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  (v27/*: any*/)
                                                ],
                                                "storageKey": "confluenceRemoteIssueLinks(first:1000)"
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraConfluenceRemoteIssueLinksField"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraCmdbObjectConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedCmdbObjectsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbObjectEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbObject",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "objectId"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "label"
                                                      },
                                                      {
                                                        "concreteType": "JiraCmdbAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbMediaClientConfig",
                                                            "kind": "LinkedField",
                                                            "name": "mediaClientConfig",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "clientId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "fileId"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "mediaBaseUrl"
                                                              },
                                                              {
                                                                "kind": "ScalarField",
                                                                "name": "mediaJwtToken"
                                                              }
                                                            ]
                                                          },
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "url48"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              },
                                              {
                                                "concreteType": "QueryError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "message"
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraCMDBField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": false,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": "componentsOld",
                                                "concreteType": "JiraComponentConnection",
                                                "kind": "LinkedField",
                                                "name": "selectedComponentsConnection",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraComponentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraComponent",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": (v34/*: any*/)
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraComponentsField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v25/*: any*/)
                                            ],
                                            "type": "JiraSingleLineTextField"
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "date"
                                          },
                                          (v17/*: any*/),
                                          (v35/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "type": "JiraDatePickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v24/*: any*/)
                                        ],
                                        "type": "JiraDateTimePickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraEpic",
                                            "kind": "LinkedField",
                                            "name": "epic",
                                            "plural": false,
                                            "selections": [
                                              (v8/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "summary"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "color"
                                              },
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraEpicLinkField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "renderedFieldHtml"
                                          }
                                        ],
                                        "type": "JiraFallbackField"
                                      },
                                      (v26/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraStatusCategory",
                                            "kind": "LinkedField",
                                            "name": "statusCategory",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              (v36/*: any*/),
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusCategoryField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "statusId"
                                              },
                                              (v37/*: any*/),
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "args": (v21/*: any*/),
                                            "concreteType": "JiraLabelConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedLabelsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraLabelEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraLabel",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v17/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              },
                                              (v27/*: any*/)
                                            ],
                                            "storageKey": "selectedLabelsConnection(first:1000)"
                                          },
                                          (v17/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "type": "JiraLabelsField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraAvatar",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "small"
                                                  }
                                                ]
                                              },
                                              (v17/*: any*/),
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraIssueTypeField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssue",
                                            "kind": "LinkedField",
                                            "name": "parentIssue",
                                            "plural": false,
                                            "selections": [
                                              (v8/*: any*/),
                                              (v38/*: any*/),
                                              (v9/*: any*/)
                                            ]
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "parentIssue",
                                                "plural": false,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  (v39/*: any*/),
                                                  (v41/*: any*/),
                                                  (v38/*: any*/),
                                                  (v9/*: any*/)
                                                ]
                                              }
                                            ]
                                          },
                                          {
                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssue",
                                                "kind": "LinkedField",
                                                "name": "parentIssue",
                                                "plural": false,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  (v39/*: any*/),
                                                  (v41/*: any*/),
                                                  (v38/*: any*/),
                                                  (v9/*: any*/),
                                                  (v7/*: any*/),
                                                  (v42/*: any*/),
                                                  {
                                                    "args": [
                                                      {
                                                        "kind": "Literal",
                                                        "name": "ids",
                                                        "value": [
                                                          "summary",
                                                          "issuetype",
                                                          "status"
                                                        ]
                                                      }
                                                    ],
                                                    "concreteType": "JiraIssueFieldConnection",
                                                    "kind": "LinkedField",
                                                    "name": "fieldsById",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssueFieldEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v15/*: any*/),
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v16/*: any*/),
                                                                  (v25/*: any*/)
                                                                ],
                                                                "type": "JiraSingleLineTextField"
                                                              },
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v16/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraIssueType",
                                                                    "kind": "LinkedField",
                                                                    "name": "issueType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      {
                                                                        "kind": "ScalarField",
                                                                        "name": "issueTypeId"
                                                                      },
                                                                      (v17/*: any*/),
                                                                      (v40/*: any*/),
                                                                      (v10/*: any*/),
                                                                      (v9/*: any*/)
                                                                    ]
                                                                  }
                                                                ],
                                                                "type": "JiraIssueTypeField"
                                                              },
                                                              {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                  (v16/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "status",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v37/*: any*/),
                                                                      (v9/*: any*/)
                                                                    ]
                                                                  }
                                                                ],
                                                                "type": "JiraStatusField"
                                                              },
                                                              (v9/*: any*/)
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                                  }
                                                ]
                                              },
                                              (v17/*: any*/),
                                              (v20/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraParentIssueField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraPriority",
                                            "kind": "LinkedField",
                                            "name": "priority",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              },
                                              (v9/*: any*/)
                                            ]
                                          },
                                          (v17/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "type": "JiraPriorityField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraProject",
                                            "kind": "LinkedField",
                                            "name": "project",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              (v8/*: any*/),
                                              {
                                                "concreteType": "JiraAvatar",
                                                "kind": "LinkedField",
                                                "name": "avatar",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "large"
                                                  }
                                                ]
                                              },
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraProjectField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraResolution",
                                            "kind": "LinkedField",
                                            "name": "resolution",
                                            "plural": false,
                                            "selections": (v34/*: any*/)
                                          }
                                        ],
                                        "type": "JiraResolutionField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraSprintConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedSprintsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprintEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraSprint",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v9/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "sprintId"
                                                      },
                                                      (v17/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "state"
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "endDate"
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          },
                                          (v17/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "type": "JiraSprintField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v15/*: any*/),
                                              (v31/*: any*/),
                                              (v32/*: any*/),
                                              (v17/*: any*/),
                                              (v9/*: any*/),
                                              (v33/*: any*/)
                                            ]
                                          },
                                          (v17/*: any*/),
                                          (v35/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "type": "JiraSingleSelectUserPickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v17/*: any*/),
                                          {
                                            "args": (v21/*: any*/),
                                            "concreteType": "JiraVersionConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedVersionsConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraVersionEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraVersion",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v34/*: any*/)
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "selectedVersionsConnection(first:1000)"
                                          }
                                        ],
                                        "type": "JiraMultipleVersionPickerField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraRichText",
                                            "kind": "LinkedField",
                                            "name": "richText",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraADF",
                                                "kind": "LinkedField",
                                                "name": "adfValue",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "condition": "isDensityFull",
                                                    "kind": "Condition",
                                                    "passingValue": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraAdfToConvertedPlainText",
                                                        "kind": "LinkedField",
                                                        "name": "convertedPlainText",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "ScalarField",
                                                            "name": "plainText"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  },
                                                  {
                                                    "condition": "isDensityFull",
                                                    "kind": "Condition",
                                                    "passingValue": true,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "json"
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ],
                                        "type": "JiraRichTextField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "JiraServiceManagementRequestType",
                                            "kind": "LinkedField",
                                            "name": "requestType",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              (v40/*: any*/),
                                              (v9/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraServiceManagementRequestTypeField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "first",
                                                "value": 1
                                              }
                                            ],
                                            "concreteType": "JiraIssueLinkConnection",
                                            "kind": "LinkedField",
                                            "name": "issueLinkConnection",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/),
                                              {
                                                "concreteType": "JiraIssueLinkEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueLink",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "concreteType": "JiraIssue",
                                                        "kind": "LinkedField",
                                                        "name": "issue",
                                                        "plural": false,
                                                        "selections": [
                                                          (v42/*: any*/),
                                                          (v8/*: any*/),
                                                          {
                                                            "args": [
                                                              {
                                                                "kind": "Literal",
                                                                "name": "ids",
                                                                "value": [
                                                                  "statusCategory"
                                                                ]
                                                              }
                                                            ],
                                                            "concreteType": "JiraIssueFieldConnection",
                                                            "kind": "LinkedField",
                                                            "name": "fieldsById",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraIssueFieldEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                  {
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v15/*: any*/),
                                                                      {
                                                                        "kind": "InlineFragment",
                                                                        "selections": [
                                                                          (v37/*: any*/)
                                                                        ],
                                                                        "type": "JiraStatusCategoryField"
                                                                      },
                                                                      (v9/*: any*/)
                                                                    ]
                                                                  }
                                                                ]
                                                              }
                                                            ],
                                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                                          },
                                                          (v9/*: any*/)
                                                        ]
                                                      },
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "relationName"
                                                      },
                                                      (v9/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "issueLinkConnection(first:1)"
                                          }
                                        ],
                                        "type": "JiraIssueLinkField"
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "isStoryPointField"
                                              },
                                              (v43/*: any*/),
                                              (v17/*: any*/),
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": false,
                                                "selections": [
                                                  (v43/*: any*/)
                                                ]
                                              },
                                              {
                                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                "kind": "Condition",
                                                "passingValue": true,
                                                "selections": [
                                                  (v43/*: any*/),
                                                  (v17/*: any*/),
                                                  (v20/*: any*/)
                                                ]
                                              },
                                              (v20/*: any*/)
                                            ],
                                            "type": "JiraNumberField"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              (v9/*: any*/),
              {
                "condition": "isHierarchyEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeField",
                    "kind": "LinkedField",
                    "name": "issueTypeField",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "issueType",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ae8466626b70d37bb5a9afbcf747f9ea6acae64e4933c999471c8bcf07caad31",
    "metadata": {},
    "name": "realtimeUpdaterOld_issueDetails_Query",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "989d81f134e68c942cac0db35e0c832f";

export default node;
