import type { ClipboardComponentType, ClipboardComponentValue } from './types.tsx';

/* Use key mapping and conditional types to construct a static list of component types that accept `null` values.
 * See https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as. */
type IsNullableComponentType<Property extends ClipboardComponentType> =
	null extends ClipboardComponentValue<Property> ? Property : never;
type NullableComponentMap = {
	[Property in ClipboardComponentType as IsNullableComponentType<Property>]: ClipboardComponentValue<Property>;
};
type NullableComponentType = keyof NullableComponentMap;

/**
 * Exhaustive assertion that will throw for all component types that do not support a `null` value. If a new
 * `ClipboardComponentType` is defined that **does not** support `null` values then it must be added to the below switch
 * case.
 */
const assertIsNullableComponentType = (type: ClipboardComponentType): NullableComponentType => {
	switch (type) {
		case 'number':
		case 'boolean':
		case 'text':
		case 'priority':
		case 'labels':
		case 'originalEstimate':
			throw new Error(`Type '${type}' does not support null values`);
		default:
			return type;
	}
};

/** Type predicate to narrow a component type to a type that supports `null` values. */
export const isNullableComponentType = (
	type: ClipboardComponentType,
): type is NullableComponentType => {
	try {
		assertIsNullableComponentType(type);
		return true;
	} catch {
		return false;
	}
};

/* Use key mapping and conditional types to construct a static list of component types that accept `string` values.
 * See https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as. */
type IsStringComponentType<Property extends ClipboardComponentType> =
	string extends ClipboardComponentValue<Property> ? Property : never;
type StringComponentMap = {
	[Property in ClipboardComponentType as IsStringComponentType<Property>]: ClipboardComponentValue<Property>;
};
type StringComponentType = keyof StringComponentMap;

/**
 * Exhaustive assertion that will throw for all component types that do not support a `string` value. If a new
 * `ClipboardComponentType` is defined that **does not** support `string` values then it must be added to the below
 * switch case.
 */
const assertIsStringComponentType = (type: ClipboardComponentType): StringComponentType => {
	switch (type) {
		case 'number':
		case 'boolean':
		case 'checkboxSelect':
		case 'numberNullable':
		case 'priority':
		case 'sprint':
		case 'singleUser':
		case 'labels':
		case 'singleSelect':
		case 'multiUserPicker':
		case 'multiSelect':
		case 'cascadingSelect':
		case 'radioSelect':
		case 'components':
		case 'team':
		case 'multiVersionSelect':
			throw new Error(`Type '${type}' does not support string values`);
		default:
			return type;
	}
};

/** Type predicate to narrow a component type to a type that supports `string` values. */
export const isStringComponentType = (
	type: ClipboardComponentType,
): type is StringComponentType => {
	try {
		assertIsStringComponentType(type);
		return true;
	} catch {
		return false;
	}
};

/* Use key mapping and conditional types to construct a static list of component types that accept `array` values.
 * See https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as. */
type IsArrayComponentType<Property extends ClipboardComponentType> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Array<any> extends ClipboardComponentValue<Property> ? Property : never;
type ArrayComponentMap = {
	[Property in ClipboardComponentType as IsArrayComponentType<Property>]: ClipboardComponentValue<Property>;
};
type ArrayComponentType = keyof ArrayComponentMap;

/**
 * Exhaustive assertion that will throw for all component types that do not support an `array` value. If a new
 * `ClipboardComponentType` is defined that **does not** support `array` values then it must be added to the below
 * switch case.
 */
const assertIsArrayComponentType = (type: ClipboardComponentType): ArrayComponentType => {
	switch (type) {
		case 'date':
		case 'dateTime':
		case 'text':
		case 'number':
		case 'numberNullable':
		case 'boolean':
		case 'sprint':
		case 'originalEstimate':
		case 'singleUser':
		case 'singleSelect':
		case 'url':
		case 'priority':
		case 'cascadingSelect':
		case 'radioSelect':
		case 'team':
			throw new Error(`Type '${type}' does not support array values`);
		default:
			return type;
	}
};

/** Type predicate to narrow a component type to a type that supports `array` values. */
export const isArrayComponentType = (type: ClipboardComponentType): type is ArrayComponentType => {
	try {
		assertIsArrayComponentType(type);
		return true;
	} catch {
		return false;
	}
};
